import { useImperativeHandle, forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default forwardRef(function InputAndConfirmDialog(
  props: {
    okHandler?: Function;
    cancelHandler?: Function;
    content?: string;
    cancelText: string;
    title: string;
    okText: string;
  },
  ref: any
) {
  const { okHandler, okText, content, cancelText, title, cancelHandler } =
    props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleInputRestaurantIdschange = (e: any) => {
    setValue(e.target.value);
  };
  useImperativeHandle(ref, () => ({
    setOpen,
  }));
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title || ""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content || ""}
          </DialogContentText>
          <TextField
            margin="normal"
            required
            fullWidth
            id="restaurantids"
            label="Restaurant ids (no space, comma separated)"
            name="restaurantids"
            value={value}
            onChange={handleInputRestaurantIdschange}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(cancelHandler || handleClose) as any}>
            {cancelText || ""}
          </Button>
          <Button
            disabled={!value}
            onClick={() => {
              if (okHandler) {
                okHandler(value);
              }
              handleClose();
            }}
            autoFocus
          >
            {okText || ""}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
