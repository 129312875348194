import LayoutMain from "../components/Layout/LayoutMain";
import BodyDashboard from "../components/Body/Dashboard";

export default function Dashboard() {
  return (
    <LayoutMain>
      <BodyDashboard></BodyDashboard>
    </LayoutMain>
  );
}
