import Alert from "@mui/material/Alert";

export default function ErrorToast({
  message,
  onClose,
}: {
  message: string;
  onClose: Function;
}) {
  return message ? (
    <Alert onClose={onClose as any} severity="error" color="error">
      {message}
    </Alert>
  ) : (
    <></>
  );
}
