import LayoutMain from "../components/Layout/LayoutMain";
import BodySettings from "../components/Body/Settings";

export default function Settings() {
  return (
    <LayoutMain>
      <BodySettings></BodySettings>
    </LayoutMain>
  );
}
