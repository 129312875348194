import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ApiAction({ loading, disabled, ...props }: any) {
  return (
    <LoadingButton {...props} disabled={disabled}>
      {!loading ? (
        <PlayCircleOutlineIcon color={!disabled ? "primary" : "disabled"} />
      ) : (
        <HourglassEmptyOutlinedIcon color="warning" />
      )}
    </LoadingButton>
  );
}
