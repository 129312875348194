import { gql } from "@apollo/client";

export const getJobStatQql = gql`
  query getJobStats {
    storyTelling: getJobStat(input: { jobName: "storyTelling" }) {
      code
      success
      payload {
        lastRun
      }
    }
    heroBanner: getJobStat(input: { jobName: "heroBanner" }) {
      code
      success
      payload {
        lastRun
      }
    }
    tabsWithTitle: getJobStat(input: { jobName: "tabsWithTitle" }) {
      code
      success
      payload {
        lastRun
      }
    }
    beerClubRelease: getJobStat(input: { jobName: "beerClubRelease" }) {
      code
      success
      payload {
        lastRun
      }
    }
    clearCacheCloudfront: getJobStat(
      input: { jobName: "clearCacheCloudfront" }
    ) {
      code
      success
      payload {
        lastRun
      }
    }
    productAndMenu: getJobStat(input: { jobName: "productAndMenu" }) {
      code
      success
      payload {
        lastRun
      }
    }
    productAndMenuMultiRestaurants: getJobStat(
      input: { jobName: "productAndMenuMultiRestaurants" }
    ) {
      code
      success
      payload {
        lastRun
      }
    }
  }
`;
export const getAllSettingsQql = gql`
  query GetAllSettings {
    getAllSettings(input: {}) {
      code
      success
      payload {
        allSettings {
          key
          lastRun
        }
      }
    }
  }
`;
const exportGql = {
  getJobStatQql,
  getAllSettingsQql,
};

export default exportGql;
