import { useEffect } from "react";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ErrorToast from "../components/Error/Toast";
import { useMutation } from "@apollo/client";
import {
  clearUserData,
  getUserData,
  setUserData,
} from "../services/util.service";
import { useNavigate } from "react-router-dom";
import { loginGql } from "../services/mutation.service";

export default function SignIn() {
  useEffect(() => {
    const user = getUserData();
    if (user.token) {
      navigate("/");
    }
  });
  const [loginError, setLoginError] = useState({
    message: "",
  });
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(loginGql, {
    onCompleted: (data) => {
      setUserData(data.login.payload);
      navigate("/dashboard");
    },
    onError: (error) => {
      clearUserData();
      setLoginError({ message: error.message });
    },
  });
  const [loginInfo, setLoginInfo] = useState({} as any);
  const [errorUserName, setErrorUserName] = useState({
    error: false,
    helperText: "",
  });
  const [errorPassword, setErrorPassword] = useState({
    error: false,
    helperText: "",
  });
  function setUsername(e: any) {
    setLoginInfo({
      ...loginInfo,
      username: e.target.value,
    });
  }
  function setPassword(e: any) {
    setLoginInfo({
      ...loginInfo,
      password: e.target.value,
    });
  }
  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    if (!loginInfo.username) {
      setErrorUserName({
        error: true,
        helperText: "Username is required",
      });
    }
    if (!loginInfo.password) {
      setErrorPassword({
        error: true,
        helperText: "Password is required",
      });
    }
    loginInfo.username &&
      loginInfo.password &&
      login({ variables: { input: loginInfo } });
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TextField
            error={errorUserName.error}
            helperText={errorUserName.helperText}
            disabled={loading}
            margin="normal"
            required
            fullWidth
            id="usernam"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={setUsername}
          />
          <TextField
            error={errorPassword.error}
            helperText={errorPassword.helperText}
            disabled={loading}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={setPassword}
          />
          <LoadingButton
            type="submit"
            // disabled={errorPassword.error || errorUserName.error}
            loading={loading}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <ErrorToast
            message={loginError.message}
            onClose={setLoginError.bind(null, {
              message: "",
            })}
          ></ErrorToast>
        </Box>
      </Box>
    </Container>
  );
}
