export function setUserData(data: any) {
  window && window.localStorage.setItem("BJS_USERNAME", data.username);
  window && window.localStorage.setItem("BJS_TOKEN", data.token);
  window && window.sessionStorage.setItem("BJS_ROLES", JSON.stringify(data.roles ?? []));
  window && window.sessionStorage.setItem("BJS_PERMISSIONS", JSON.stringify(data.permissions ?? []));
}

export function clearUserData() {
  setUserData({
    username: "",
    token: "",
    permissions: [],
  });
}

export function getUserData() {
  if (!window) {
    return {
      username: '',
      token: '',
      roles: [],
      permissions: [],
    };
  }

  const rolesString = window ? window.sessionStorage.getItem('BJS_ROLES') : '';
  const roles = rolesString ? JSON.parse(rolesString) : [];

  const permissionsString = window ? window.sessionStorage.getItem('BJS_PERMISSIONS') : '';
  const permissions = permissionsString ? JSON.parse(permissionsString) : [];

  const userData = {
    username: window ? window.localStorage.getItem('BJS_USERNAME') : '',
    token: window ? window.localStorage.getItem('BJS_TOKEN') : '',
    roles,
    permissions,
  };

  return userData;
}

/**
 * Get permission of the page for the current user.
 * The permission is based on the user's roles.
 *
 * @param page
 * @returns
 */
export function getPermission(page: string): { view: boolean, edit: boolean } {
  const { permissions } = getUserData();
  if (!permissions || !permissions.length) {
    return {
      view: false,
      edit: false,
    };
  }

  // Check if user has permission to view and edit the page
  let view = false;
  let edit = false;

  for (const permission of permissions) {
    const { page: permissionPage, actions } = permission;
    if (permissionPage === page || permissionPage === '*') {
      if (actions.includes('*')) {
        view = true;
        edit = true;
        break;
      }

      if (actions.includes('view')) {
        view = true;
      }

      if (actions.includes('edit')) {
        edit = true;
      }
    }
  }

  return { view, edit };
}

export function converDateTimeToPst(now?: any) {
  const dateTime = now ? new Date(now) : new Date();
  return dateTime.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
}
export function formatSuccessData(data: any) {
  return {
    ...data,
    lastRun: converDateTimeToPst(),
    value: "success",
    message: "ok",
  };
}

export function formatErrorData(err: any) {
  return {
    lastRun: converDateTimeToPst(),
    value: "error",
    message: err.message,
  };
}

const methods = {
  setUserData,
  getUserData,
  formatSuccessData,
  formatErrorData,
};
export default methods;
