import { useState, forwardRef, useImperativeHandle } from "react";
import { useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StatusChip from "../../Common/StatusChip";
import ApiAction from "../../Common/ApiAction";
import { updateCacheGqlV3 } from "../../../services/mutation.service";
import {
  converDateTimeToPst,
  formatErrorData,
  formatSuccessData,
} from "../../../services/util.service";

function createData(name: string) {
  return {
    name,
    lastRun: "Unknown",
    isBusy: false,
  };
}
function getStatus(state: any) {
  if (state.loading) {
    return {
      value: "warning",
      message: "Running",
    };
  }
  return {
    value: state.value,
    message: state.message,
  };
}

export default forwardRef(function UpdateHeroBannerCache(
  props: {
    isBusy: boolean;
    disabled?: boolean;
    onClick: Function;
    onCompleted?: Function;
    onError?: Function;
  },
  ref: any
) {
  const { isBusy, disabled, onClick, onCompleted = () => {}, onError } = props;
  const newOnError = onError || onCompleted;
  const [status, setStatus] = useState({
    ...createData('Purge "Hero Banner" cache'),
    value: "success",
    message: "Ok",
  });
  const [update, { loading: isLoading }] = useMutation(updateCacheGqlV3, {
    variables: {
      input: {
        cacheName: "heroBanner",
      },
    },
    onCompleted: (data: any) => {
      const formatData = formatSuccessData(data);
      setStatus((prev) => {
        return {
          ...prev,
          ...formatData,
          lastRun: converDateTimeToPst(
            new Date(+formatData.updateCacheV3.payload.lastRun)
          ),
        };
      });
      onCompleted();
    },
    onError: (err: any) => {
      const formatData = formatErrorData(err);
      setStatus((prev) => {
        return {
          ...prev,
          ...formatData,
        };
      });
      newOnError();
    },
  });
  useImperativeHandle(ref, () => ({
    action: update,
    isLoading,
    setStatus,
    status,
  }));
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          {status.name}
          <Typography color="error" variant="subtitle2">
            **Take up to 5 minutes until affect!
          </Typography>
        </TableCell>
        <TableCell>{status.lastRun}</TableCell>
        <TableCell>
          <StatusChip status={getStatus(status)}></StatusChip>
        </TableCell>
        <TableCell align="center">
          <ApiAction
            onClick={(e: any) => {
              if (isBusy) {
                return;
              }
              onClick(e);
            }}
            loading={!!isLoading}
            disabled={isBusy || !!disabled}
          ></ApiAction>
        </TableCell>
      </TableRow>
    </>
  );
});
