import { useState, forwardRef, useImperativeHandle } from "react";
import { useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StatusChip from "../../Common/StatusChip";
import ApiAction from "../../Common/ApiAction";
import { updateMenuMultiRestaurantsCacheGqlV3 } from "../../../services/mutation.service";
import {
  converDateTimeToPst,
  formatErrorData,
  formatSuccessData,
} from "../../../services/util.service";

function createData(name: string) {
  return {
    name,
    lastRun: "Unknown",
    isBusy: false,
  };
}
function getStatus(state: any) {
  if (state.loading) {
    return {
      value: "warning",
      message: "Running",
    };
  }
  return {
    value: state.value,
    message: state.message,
  };
}

export default forwardRef(function UpdateMenuItemCacheForMultiRestaurants(
  props: {
    hidden?: boolean;
    isBusy: boolean;
    disabled?: boolean;
    onClick: Function;
    onCompleted?: Function;
    onError?: Function;
  },
  ref: any
) {
  const { hidden, isBusy, disabled, onClick, onCompleted = () => {}, onError } = props;
  const newOnError = onError || onCompleted;
  const [status, setStatus] = useState({
    ...createData('Purge "Menu Item" cache for one or many restaurants'),
    value: "success",
    message: "Ok",
  });
  const [update, { loading: isLoading }] = useMutation(
    updateMenuMultiRestaurantsCacheGqlV3,
    {
      onCompleted: (data: any) => {
        const formatData = formatSuccessData(data);
        setStatus((prev) => {
          return {
            ...prev,
            ...formatData,
            lastRun: converDateTimeToPst(
              new Date(+formatData.updateMenuCacheV3.payload.lastRun)
            ),
          };
        });
        onCompleted();
      },
      onError: (err: any) => {
        const formatData = formatErrorData(err);
        setStatus((prev) => {
          return {
            ...prev,
            ...formatData,
          };
        });
        newOnError();
      },
    }
  );
  useImperativeHandle(ref, () => ({
    action: update,
    isLoading,
    setStatus,
    status,
  }));
  return hidden ? (
    <></>
  ) : (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          {status.name}
          <Typography color="error" variant="subtitle2">
            **Take up to 15 minutes until affect! Be cautious! It is heavy task!
          </Typography>
        </TableCell>
        <TableCell>{status.lastRun}</TableCell>
        <TableCell>
          <StatusChip status={getStatus(status)}></StatusChip>
        </TableCell>
        <TableCell align="center">
          <ApiAction
            onClick={(e: any) => {
              if (isBusy) {
                return;
              }
              onClick(e);
            }}
            loading={!!isLoading}
            disabled={!!disabled || isBusy}
          ></ApiAction>
        </TableCell>
      </TableRow>
    </>
  );
});
