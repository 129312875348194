import "./App.css";
import SignIn from "./pages/SignIn";
import { ApolloProvider } from "@apollo/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import PageDashboard from "./pages/Dashboard";
import PageSettings from "./pages/Settings";
import initGraphQLClient from "./services/graphql.service";

let router = createBrowserRouter([
  {
    path: "/",
    Component: PageDashboard,
  },
  {
    path: "/login",
    Component: SignIn,
  },
  {
    path: "/dashboard",
    Component: PageDashboard,
  },
  {
    path: "/settings",
    Component: PageSettings,
  },
]);
function App() {
  const client = initGraphQLClient();
  return (
    <>
      <CssBaseline />
      <ApolloProvider client={client}>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
      </ApolloProvider>
    </>
  );
}

export default App;
